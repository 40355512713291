<template>
  <div>
    <!-- unlock user -->
    <b-card title="Desbloquear usuario">
      Como usuario de seguridad, tienes la capacidad de desbloquear a este usuario bloqueado. Al desbloquearlo, el usuario podrá restablecer su acceso y continuar con sus actividades. ¿Deseas proceder con el desbloqueo?
      <b-row class="mt-2">
        <b-col>
          <b-button
              variant="success"
              @click="handleUnlock"
              :disabled="isBusy"
          >
            <template v-if="isBusy">
              <b-spinner small />
              Desbloqueando...
            </template>
            <template v-else>
              Desbloquear usuario
            </template>
          </b-button>

        </b-col>
      </b-row>
    </b-card>
  </div>
</template>


<script>

import { BButton, BCard, BCol, BRow, BSpinner } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  name: 'UnlockUser',

  props: {
    usuarioId: {
      type: Number,
      required: true,
      default: 0
    }
  },

  components: {
    BButton,
    BCard,
    BCol,
    BRow,
    BSpinner
  },

  data(){
    return {
      isBusy: false,

    }
  },

  methods: {

    ...mapActions('usuario-module', ['unlockUsuario']),

    async handleUnlock(){

      try {

        this.isBusy = true

        await this.unlockUsuario( this.usuarioId )

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Operación exitosa',
            icon: 'CheckIcon',
            text: 'El usuario ha sido desbloqueado exitosamente',
            variant: 'success'
          },
        })

      } catch (error) {

      } finally {

        this.isBusy = false

      }

    }


  },



}
</script>


<style scoped lang="scss">

</style>
